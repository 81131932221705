
import SlotDTO from '@/app/DTOs/scheduling/SlotDTO';
import AppSchedulingService from '@/app/services/AppSchedulingService';
import { VsConfig, VsOption } from '@/components';
import { VsIcon } from '@/components/controls/vs-icon';
import AuthResponse from '@/models/AuthResponse';
import ServiceDTO from '@/modules/academic/DTOs/services/ServiceDTO';
import TeacherDTO from '@/modules/academic/DTOs/teachers/TeacherDTO';
import CreatedDTO from '@/modules/core/DTOs/CreatedDTO';
import CreateEventDTO from '@/modules/scheduling/DTOs/events/CreateEventDTO';
import store from '@/modules/scheduling/stores/newSchedulingStore';
import { addMinutes } from 'date-fns';
import { Component, Vue } from 'vue-property-decorator';
import CalendarDTO from '../DTOs/calendars/CalendarDTO';
import SchedulingSelectedItems from './SchedulingSelectedItems.vue';
import AddressDTO from '@/modules/core/DTOs/AddressDTO';

interface Page {
    name: "category" | "date" | "teacher" | "slot" | "confirmation" | "done" | "pickupLocation";
    page: string;
    title: string;
    transitionEnter: string;
    transitionLeave: string;
    nextCaption: string;
    nextAction: Function;
    showToolbarSelectedItems: boolean;
    showBackButton: boolean;
}

@Component({ components: { VsIcon, SchedulingSelectedItems } })
class NewScheduling extends Vue {

    private audience: string = localStorage.getItem("session#pilotarapp#audience") ?? process.env.VUE_APP_DEFAULT_AUDIENCE;

    public loading: boolean = true;
    public checkingCredits: boolean = false;
    public blockingLoading: boolean = false;
    public saving: boolean = false;
    public store = store;
    public fromPainel: boolean = this.audience == "painel";

    public pages: Page[] = [
        {
            name: "category",
            page: "new-scheduling-category", 
            title: "Categorias", 
            transitionEnter: "toUpInside", 
            transitionLeave: "toUpInside", 
            nextCaption: "Continuar", 
            nextAction: this.next,
            showToolbarSelectedItems: false,
            showBackButton: true
        },
        { 
            name: "date", 
            page: "new-scheduling-date", 
            title: "Data", 
            transitionEnter: "toUpInside", 
            transitionLeave: "toUpInsideReverse", 
            nextCaption: "Continuar", 
            nextAction: this.next,
            showToolbarSelectedItems: true,
            showBackButton: true
        },
        { 
            name: "teacher", 
            page: "new-scheduling-teacher", 
            title: "Instrutores", 
            transitionEnter: "toDownInside", 
            transitionLeave: "toUpInside", 
            nextCaption: "Continuar", 
            nextAction: this.next,
            showToolbarSelectedItems: true,
            showBackButton: true
        },
        { 
            name: "slot", 
            page: "new-scheduling-slot", 
            title: "Hor�rios", 
            transitionEnter: "toDownInside", 
            transitionLeave: "toDownInside",
            nextCaption: "Continuar", 
            nextAction: this.next,
            showToolbarSelectedItems: true,
            showBackButton: true
        },
        { 
            name: "pickupLocation", 
            page: "new-scheduling-pickup-location", 
            title: "Local de encontro", 
            transitionEnter: "toDownInside", 
            transitionLeave: "toDownInside",
            nextCaption: "Continuar", 
            nextAction: this.next,
            showToolbarSelectedItems: false,
            showBackButton: true
        },
        { 
            name: "confirmation", 
            page: "new-scheduling-confirmation", 
            title: "Confirme o agendamento", 
            transitionEnter: "toUpInside", 
            transitionLeave: "toDownInsideReverse", 
            nextCaption: "Agendar aula", 
            nextAction: this.next,
            showToolbarSelectedItems: false,
            showBackButton: true
        },
        { 
            name: "done", 
            page: "new-scheduling-done", 
            title: "Aula agendada", 
            transitionEnter: "toUpInside", 
            transitionLeave: "toDownInsideReverse", 
            nextCaption: "Agendar mais aulas", 
            nextAction: this.scheduleMore,
            showToolbarSelectedItems: false,
            showBackButton: false
        }
    ];

    public get user (): AuthResponse {
        return this.$store.getters['getUser'];
    }
    
    public get calendar (): CalendarDTO {
        return store.getters['getCalendar'];
    }

    public get currentPage (): number {
        return store.getters['getCurrentPage'];
    }
    
    public get selectedService (): ServiceDTO {
        return store.getters['getSelectedService'];
    }
    
    public get selectedDay (): Date {
        return store.getters['getSelectedDay'];
    }
    
    public get selectedTeacher (): TeacherDTO {
        return store.getters['getSelectedTeacher'];
    }
    
    public get selectedAddress (): AddressDTO {
        return store.getters['getSelectedAddress'];
    }
    
    public get selectedSlots (): SlotDTO[] {
        return store.getters['getSelectedSlots'];
    }
   
    // Verifica se o bot�o de "avan�ar" pode ser habilitado
    public get nextAllowed(): boolean {
        return ((this.pages[this.currentPage].name === "category" && this.selectedService != null)
                || (this.pages[this.currentPage].name === "date" && this.selectedDay != null)
                || (this.pages[this.currentPage].name === "teacher" && this.selectedTeacher != null)
                || (this.pages[this.currentPage].name === "pickupLocation" && this.selectedAddress != null)
                || (this.pages[this.currentPage].name === "slot" && this.selectedSlots.length > 0)
                || this.currentPage >= this.pages.length - 2)
               && !this.checkingCredits;
    }

    // Percentual de progresso j� alcan�ado no agendamento
    public get progress(): number {
        return parseInt((((this.currentPage + 1) / this.pages.length) * 100).toString(), 10);
    }

    public updateCheckingCredits(value: boolean) {
        this.checkingCredits = value;
    }

    // Vai para a pr�xima etapa do agendamento
    public async next() {

        if (!this.saving) {

            if (this.currentPage != -1 && this.pages[this.currentPage].name === "confirmation") {
                if (! (await this.create())) {
                    return;
                }
            }

            const nextPage = this.currentPage + 1;

            store.commit('setCurrentPage', nextPage);

            this.$store.commit('navigate', { 
                page: this.pages[this.currentPage].page, 
                transition: this.pages[this.currentPage].transitionEnter
            });
        }
    }

    // Fecha o processo de agendamento
    public close() {
        console.log("close");
        console.log(this.fromPainel);
        if (this.fromPainel) {
            this.closeFromPainel();
        }
        else {
            store.commit('reset');
            this.$store.commit('clearStack', { page: "aulas", transition: "toUpReverse" });
        }
    }
    
    public closeFromPainel() {
        if (this.fromPainel) {
            console.log(process.env.VUE_APP_SITE_URL);
            if (this.pages[this.currentPage].name === "done") {
                window.top?.parent.postMessage("closeScheduling", process.env.VUE_APP_SITE_URL);
            }
            else {
                console.log(`post message: closeSchedulingRequest: ${process.env.VUE_APP_SITE_URL}`);
                window.top?.parent.postMessage("closeSchedulingRequest", process.env.VUE_APP_SITE_URL);
            }
        }
        else {
            this.close();
        }
    }    

    public showBlockingLoading() {
        this.blockingLoading = true;
    }

    public hideBlockingLoading() {
        this.blockingLoading = false;
    }

    public scheduleMore() {
        store.commit('reset');
        this.edit("slot");
    }

    // Retorna para a etapa anterior do agendamento
    public back() {
        if (this.currentPage === 0) {
            this.closeWithoutReset();
        }
        else {
            const nextPage = this.currentPage - 1;
            store.commit('setCurrentPage', nextPage);

            this.$store.commit('navigate', { 
                page: this.pages[nextPage].page,
                transition: this.pages[nextPage + 1].transitionLeave
            });
        }
    }

    public closeWithoutReset() {
        this.$store.commit('clearStack', { page: "aulas", transition: "toUpReverse" });
    }

    // Atualiza no estado as informa�oes alteradas durante o preenchimento das etapas
    public change(page: string, data: any) {
        if (page === "category") {
            store.commit('setSelectedService', data);
        }
        else if (page === "date") {
            const day = (data.disabled as boolean) ? null : data.selectedDay;
            store.commit('setCalendar', data.calendar);
            store.commit('setSelectedDay', day);
        }
        else if (page === "teacher") {
            store.commit('setSelectedDay', (data.disabled as boolean) ? null : data.selectedDay);
            store.commit('setSelectedTeacher', data.selectedTeacher);
        }
        else if (page === "pickupLocation") {
            console.log("passou 2");
            store.commit('setSelectedAddress', data.selectedAddress);
        }
        else if (page === "slot") {

            store.commit('setSelectedDay', (data.disabled as boolean) ? null : data.selectedDay);
            store.commit('setSelectedSlots', data.selectedSlots);
        }
    }

    // Inicia processo para adicionar mais cr�ditos a um processo do usu�rio
    public needMoreCredits(title: string, message: string): void {
        const options: VsOption[] = [
            {
                title: 'Ok'
            },
        ];

        const config: VsConfig = {
            imageHeight: "60x"
        };

        this.$pilotarAlert(title, message, options, 'coins', config);
    }

    // Vai para uma tela espec�fica, para o usu�rio editar uma informa��o
    public edit(page: string): void {
        const findedPage: any | undefined = this.pages.find(p => p.name === page);
        if (findedPage) {
            store.commit('setCurrentPage', this.pages.findIndex(p => p.name === page) - 1);
            this.next();
        }
    }

    // Vai para uma tela espec�fica, para o usu�rio editar uma informa��o
    public async create(): Promise<boolean> {
        try {

            if (this.selectedSlots != null && this.selectedDay != null && this.calendar != null && this.selectedService != null && this.selectedTeacher != null) {

                this.saving = true;

                const events: Promise<CreatedDTO>[] = [];
                for (const slot of this.selectedSlots) {
                    const newEvent: CreateEventDTO = {
                        calendarId: this.calendar?.id,
                        start: addMinutes(this.selectedDay, slot.startTimeMinutes).toJSON(),
                        end: addMinutes(this.selectedDay, slot.endTimeMinutes).toJSON(),
                        name: `${this.user.nome} - ${this.selectedService.process.type.longDescription}`,
                        resources: [this.selectedTeacher.schedulingResource.id],
                        origin: this.fromPainel ? "CFC" : "Aluno",
                        metadata: {
                            service: this.selectedService,
                            studentUserId: this.user.pilotar.user.id,
                            teacherUserId: this.selectedTeacher.user.id,
                            pickupLocation: this.selectedAddress
                        },
                        externalUserId: localStorage.getItem("externalUserId") ?? "",
                        externalUserName: localStorage.getItem("externalUserName") ?? ""
                    };

                    events.push(AppSchedulingService.createEvent(newEvent));
                }

                try {

                    const results = await Promise.allSettled(events);

                    for (let i = 0; i < results.length; i ++) {
                        const result: any = results[i];
                        if (result.value == null || result.value == undefined)
                            this.selectedSlots[i].error = 'Hor�rio n�o estava mais dispon�vel';
                        else
                            this.selectedService.quantityAvailable --;
                    }

                    return true;
                }
                catch(error) {
                    this.$pilotarAlert('Erro', 'Erro ao registrar seu agendamento. Tente novamente em alguns instantes.');
                    console.log(error);
                }
                finally {
                    this.saving = false;
                }


            }
            else  {
                console.log("erro aqui");
                this.$pilotarAlert('Erro', 'Erro ao registrar seu agendamento. Tente novamente em alguns instantes.');
            }
        } 
        catch(error) {
            console.log(error);
            this.$pilotarAlert('Erro', 'Erro ao registrar seu agendamento. Tente novamente em alguns instantes.');
        }
        return false;
    }

}

export default NewScheduling;
