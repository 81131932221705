
import { VsIcon } from '@/components/controls/vs-icon';
import AuthResponse from '@/models/AuthResponse';
import ServiceDTO from '@/modules/academic/DTOs/services/ServiceDTO';
import TeacherDTO from '@/modules/academic/DTOs/teachers/TeacherDTO';
import TeachersService from '@/modules/academic/services/TeachersService';
import UserAvatar from '@/modules/auth/components/userAvatar/UserAvatar.vue';
import NotFoundMessage from '@/modules/core/components/NotFoundMessage.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SchedulingCalendar from './SchedulingCalendar.vue';
import SchedulingSelectedItems from './SchedulingSelectedItems.vue';
import { titleCase } from '@/utils/stringUtils';

@Component({ components: { VsIcon, SchedulingCalendar, SchedulingSelectedItems, NotFoundMessage, UserAvatar } })
export default class NewSchedulingTeacherPage extends Vue {

    @Prop({ type: Date })
    public initialDay!: Date;

    @Prop({ type: Object as () => ServiceDTO })
    public initialService!: ServiceDTO;

    @Prop({ type: Object as () => TeacherDTO })
    public initialTeacher!: TeacherDTO;

    public calendarLoading: boolean = true;
    public teachersLoading: boolean = true;
    public selectedDay: Date = this.initialDay;
    public selectedDayDisabled: boolean = false;
    public selectedTeacher: TeacherDTO | null = this.initialTeacher;
    public selectedService: ServiceDTO = this.initialService;
    public teachers: TeacherDTO[] | null = null;

    public get user (): AuthResponse {
        return this.$store.getters['getUser'];
    }

    public get loading (): boolean {
        return this.calendarLoading || this.teachersLoading;
    }

    public get notFound (): boolean {
        return this.teachers != null && this.teachers.length === 0;
    }

    @Watch('selectedDay')
    private onSelectedDayChange() {
        this.teachers = [];
        if (!this.selectedDayDisabled) {
            this.fetchData();
        }
    }

    public daySelect(day: Date, isDisabled: boolean): void {
        this.selectedDayDisabled = isDisabled;
        this.selectedDay = day;
        this.selectedTeacher = null;
        this.$emit("change", "teacher", { 
            selectedTeacher: null, 
            selectedDay: this.selectedDay, 
            disabled: this.selectedDayDisabled
        });
    }

    public titleCase (text: string): string {
        return titleCase(text);
    }
    
    public calendarLoaded(): void {
        this.calendarLoading = false;
    }

    public click(teacher: TeacherDTO) {
        this.selectedTeacher = teacher;

        this.$emit("change", "teacher", { 
            selectedTeacher: teacher, 
            selectedDay: this.selectedDay, 
            disabled: this.selectedDayDisabled
        });
    }    

    public next() {
        this.$store.commit('navigate', { 
            page: 'new-scheduling-slots', 
            params: { 
                selectedDay: this.selectedDay, 
                selectedTeacher: this.selectedTeacher,
                selectedService: this.$route.params.selectedService
            }, 
            transition: 'toUp'
        });
    }
    
    private async fetchData() {
        this.teachersLoading = true;

        try {
            const teachers: TeacherDTO[] = await TeachersService.getAvailableForScheduling(this.selectedDay.toJSON().substring(0, 10), this.selectedService.id);
            if (teachers != null) {
                teachers.sort((a, b) => a.name > b.name ? 1 : -1);
                this.teachers = teachers;
            }
        }
        catch(error) {
            this.$pilotarAlert('Opss...', 'Erro ao conectar no servidor, tente novamente em alguns instantes', [
                {
                    title: "Tentar novamente",
                    primary: true
                }
            ]);
        }
        finally {
            this.teachersLoading = false;
        }

    }

    private async mounted() {
        await this.fetchData();
    }
}

