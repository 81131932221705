import httpPilotar from '@/plugins/httpPilotar';
import { Get } from '@/services/BaseService';
import ResourceDTO from '../DTOs/resources/ResourceDTO';

export default class ResourcesService {

    public static getByIdentifier(identifier: string): Promise<ResourceDTO>{
        return Get<ResourceDTO>(`/scheduling/resources/get-by-identifier`, { "identifier": identifier }, { customClient: httpPilotar });
    }
  
    public static getBySession(): Promise<ResourceDTO>{
        return Get<ResourceDTO>(`/scheduling/resources/get-by-session`, { }, { customClient: httpPilotar });
    }
  
}