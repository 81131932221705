import httpPilotar from '@/plugins/httpPilotar';
import { Get } from '@/services/BaseService';
import AvailabilityBlockDTO from '../DTOs/availabilityBlocks/AvailabilityBlockDTO';

export default class AvailabilityBlocksService {

    public static getByIdentifier(identifier: string): Promise<AvailabilityBlockDTO[]>{
        return Get<AvailabilityBlockDTO[]>(`/scheduling/availability-blocks/get-by-resource-identifier`, { "identifier": identifier }, { customClient: httpPilotar });
    }
  
}