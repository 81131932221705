
import EventDetailsDTO from '@/app/DTOs/scheduling/EventDetailsDTO';
import { VsIcon } from '@/components/controls/vs-icon';
import UserReferenceDTO from '@/modules/auth/DTOs/UserReferenceDTO';
import UserAvatar from '@/modules/auth/components/userAvatar/UserAvatar.vue';
import { dateText } from '@/utils/dateUtils';
import { formatDate, addressText } from '@/utils/stringUtils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import TimeSlotDTO from '../DTOs/calendars/TimeSlotDTO';
import SchedulingCalendar from './SchedulingCalendar.vue';
import SchedulingSelectedItems from './SchedulingSelectedItems.vue';
import AddressDTO from '@/modules/core/DTOs/AddressDTO';


interface Slot extends TimeSlotDTO {
    selected: boolean;
}

@Component({ components: { VsIcon, SchedulingCalendar, SchedulingSelectedItems, UserAvatar } })
class SchedulingDetails extends Vue {

    @Prop({ type: Object as () => EventDetailsDTO })
    public details!: EventDetailsDTO;

    public get teacherUser(): UserReferenceDTO | undefined {
        return this.details.participants.find(participant => participant.labels.includes('instructor'))?.user;
    }

    public get startDateString(): string {
        if (this.startDate != null)
            return dateText(this.startDate);
        return "";
    }

    public get startDate(): Date | null {
        if (this.details.start)
            return new Date(this.details.start);
        return null;
    }

    public get endDate(): Date | null {
        if (this.details.end)
            return new Date(this.details.end);
        return null;
    }

    public get startTimeString(): string | null {
        if (this.startDate)
            return formatDate(this.startDate, "HH:mm");
        return null;
    }

    public get endTimeString(): string | null {
        if (this.endDate)
            return formatDate(this.endDate, "HH:mm");
        return null;
    }

    public get addressText(): string | null {
        const address: AddressDTO = this.details.metadata['pickupLocation'] as AddressDTO;
        if (address)
            return addressText(address);
        return null;
    }

    public get address(): AddressDTO | null {
        return this.details.metadata['pickupLocation'] as AddressDTO;
    }

    public get durationInMinutes(): number {
        if (this.startDate && this.endDate)
            return Math.round((this.endDate.getTime() - this.startDate.getTime()) / 60000);
        return 0;
    }
}

export default SchedulingDetails;
