import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import $store from '@/store/index';
const getUserId = () => $store.getters["getUser"]?.email || null;

const insights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.VUE_APP_INSIGHTS_KEY
    }
});

const eventNames = {
    USER_SIGNIN: "user_signin",
    USER_SIGNOUT: "user_signout",
    STARTED_THEORIC_TEST: "started_theoric_test",
    STARTED_PRACTICAL_TEST: "started_practical_test",
    UNAVAIABLE_PRACTICAL_TEST: "unavaiable_practical_test",
    RATED_LESSON: "rated_lesson",
    SEARCHED_CFC: "searched_cfc",
    TAPPED_CALL_BUTTON: "tapped_call_button",
    TAPPED_ATTENDANCE_BUTTON: "tapped_attendance_button"

};

const registerCustomEvent = (eventName: string, properties?: {[key: string]: any}) => insights.trackEvent({ name: eventName, properties: { userId: getUserId(), ...properties } });
const trackPage = (name: string, uri: string, properties?: {[key: string]: any}) => insights.trackPageView({ name: name, uri: uri, properties: { userId: getUserId(), ...properties } });

const trackers = {
    trackPage: trackPage,
    registerUserSignIn: () => registerCustomEvent(eventNames.USER_SIGNIN),
    registerUserSignOut: () => registerCustomEvent(eventNames.USER_SIGNOUT),
    registerStartedThericTest: () => registerCustomEvent(eventNames.STARTED_THEORIC_TEST),
    registerStartedPracticalTest: (track: string) => registerCustomEvent(eventNames.STARTED_PRACTICAL_TEST, { track }),
    registerUnavaiablePracticalTest: (track: string) => registerCustomEvent(eventNames.UNAVAIABLE_PRACTICAL_TEST, { track }),
    registerRatedLesson: () => registerCustomEvent(eventNames.RATED_LESSON),
    registerSearchedCfc: () => registerCustomEvent(eventNames.SEARCHED_CFC),
    registerTappedCallButton: () => registerCustomEvent(eventNames.TAPPED_CALL_BUTTON),
    registerTappedAttendanceButton: () => registerCustomEvent(eventNames.TAPPED_ATTENDANCE_BUTTON)
};



insights.loadAppInsights();

export default insights;
export { trackers };