import httpPilotar from '@/plugins/httpPilotar';
import { Get } from '@/services/BaseService';
import FileDTO from '../DTOs/FileDTO';

export default {
    getByFolder:(folder: string, includeBase64: boolean) => {
        return Get<FileDTO[]>(`/storage/files/get-by-folder?folder=${folder}&includeBase64=${includeBase64}`, {}, { customClient: httpPilotar });
    },
    getById:(id: string, includeBase64: boolean) => {
        return Get<FileDTO>(`/storage/files/get-by-id?id=${id}&includeBase64=${includeBase64}`, {}, { customClient: httpPilotar });
    }
};