
import { VsIcon } from '@/components/controls/vs-icon';
import AuthResponse from '@/models/AuthResponse';
import { MILESECONDS_IN_DAY, MONTHS, WEEKDAYS } from '@/utils/dateUtils';
import { sentenceCase } from '@/utils/stringUtils';
import { addDays, addMinutes, endOfDay, isToday, startOfDay, startOfWeek } from 'date-fns';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AvailabilityBlockDTO from '../DTOs/availabilityBlocks/AvailabilityBlockDTO';
import CalendarDTO from '../DTOs/calendars/CalendarDTO';
import ResourceDTO from '../DTOs/resources/ResourceDTO';
import AvailabilityBlocksService from '../services/AvailabilityBlocksService';
import CalendarsService from '../services/CalendarsService';
import ResourcesService from '../services/ResourcesService';
import SchedulingDay from './SchedulingDay.vue';

interface CompactDay {
    aheadFromDay: number;
    date: Date;
}

// Import Swiper styles
import 'swiper/swiper-bundle.css';

@Component({ components: { VsIcon, SchedulingDay, Swiper, SwiperSlide } })
class NewSchedulingDate extends Vue {

    @Prop({ type: String, default: "calendar" })
    public startMode!: "compact" | "calendar";

    @Prop({ type: Date })
    public day!: Date | null;

    private audience: string = localStorage.getItem("session#pilotarapp#audience") ?? process.env.VUE_APP_DEFAULT_AUDIENCE;
    public fromPainel: boolean = this.audience == "painel";

    public loading: boolean = true;
    public resource: ResourceDTO | null = null;
    public calendar: CalendarDTO | null = null;
    public availabilityBlocks: AvailabilityBlockDTO[] | null = null;
    public selectedDay: Date | null = this.day;
    public compactWeeks: number = 0;
    public addDays = addDays;
    public addMinutes = addMinutes;
    public sentenceCase = sentenceCase;
    public WEEKDAYS = WEEKDAYS;

    public attributes: any = [
        {
            highlight: false
        }
    ];
    
    public get user (): AuthResponse {
        return this.$store.getters['getUser'];
    }
    

    public get maxDate (): Date {
        return addMinutes(startOfDay(new Date()), this.calendar?.eventCreationMaxThreshold?.minutes ?? 0);
    }

    public get minDate (): Date {
        if (this.fromPainel){
            return startOfDay(new Date());
        }
        return addMinutes(startOfDay(new Date()), this.calendar?.eventCreationMinThreshold?.minutes ?? 0);
    }

    public get compactMonthLabel (): string {
        if (this.selectedDay) {
            return `${sentenceCase(MONTHS[this.selectedDay.getMonth()])} de ${this.selectedDay.getFullYear()}`;
        }
        return "";
    }

    public get selectedDayMessage (): string {
        if (this.selectedDay) {
            const dayBlocked: AvailabilityBlockDTO | null | undefined = this.getDayBlocked(this.selectedDay);

            if (this.selectedDay < startOfDay(new Date()))
                return "Data menor que o dia atual";

            if (dayBlocked)
                return `${dayBlocked.type.description} - ${dayBlocked.description}`;

            if (this.daysOfWeekUnavailable?.includes(this.selectedDay.getDay()))
                return "Autoescola n�o abre nesse dia";

            if (this.selectedDay < this.minDate)
                return `Agendar com ${this.calendar?.eventCreationMinThreshold.value} ${this.calendar?.eventCreationMinThreshold.type.description.toLowerCase()} de anteced�ncia`;

            if (this.selectedDay > this.maxDate)
                return `N�o � poss�vel agendar ap�s ${this.calendar?.eventCreationMaxThreshold.value} ${this.calendar?.eventCreationMaxThreshold.type.description.toLowerCase()}`;

            if (this.isLastMinuteDay(this.selectedDay))
                return 'Aviso: Agendamento com pouca anteced�ncia.';

            return "Data dispon�vel para agendamento";
        }
        return "";
    }

    public nextDays(day: Date, days: number): CompactDay[] {
        const start: number = startOfDay(day).getTime();
        const arrDays: CompactDay[] = [];
        let stepsAhead: number = 0;
        for (let time = start; time <= start + (MILESECONDS_IN_DAY * days); time += MILESECONDS_IN_DAY) {
            arrDays.push({ aheadFromDay: stepsAhead, date: new Date(time) });
            stepsAhead ++;
        }
        return arrDays;
    }

    public dayClick(day: Date): void {
        this.selectedDay = startOfDay(day);
        this.$emit("daySelect", day, this.isDisabled(day), this.calendar);
    }

    // Retorna a lista de dias de uma semana, utilizando uma data como referencia
    public daysInWeek(referenceDay: Date, week: number): CompactDay[] {

        referenceDay = addDays(startOfDay(referenceDay), 7 * (week - 1));

        const arrDays: CompactDay[] = [];

        //Monta os dias que est�o antes da data de refer�ncia
        for (let i = 0; i < referenceDay.getDay(); i ++){
            const ahead: number = i - referenceDay.getDay();
            arrDays.push( { aheadFromDay: ahead, date: new Date(referenceDay.getTime() + (ahead * MILESECONDS_IN_DAY)) } );
        }

        //Monta o dia da data de refer�ncia
        arrDays.push( { aheadFromDay: 0, date: referenceDay } );

        //Monta os dias que est�o depois da data de refer�ncia
        for (let i = 1; i <= 6 - referenceDay.getDay(); i ++){
            const ahead: number = i;
            arrDays.push( { aheadFromDay: ahead, date: addDays(referenceDay, ahead) } );
        }

        return arrDays;
    }

    // Verifica se um determinado dia � o dia que que est� selecionado
    public isDaySelected(day: Date): boolean {
        if (day)
            return startOfDay(this.selectedDay || new Date()).getTime() === startOfDay(day).getTime();
        return false;
    }

    // Retorna o texto que deve ser apresentado para um dia
    public dayCaption(day: Date): string {
        day = startOfDay(day);
        return `${day.getDate() < 10 ? 0 : ''}${day.getDate()}`;
    }

    public isToday(day: Date): boolean {
        return isToday(startOfDay(day));
    }

    // Verifica se um determinado dia dever� estar desabilitado para o usu�rio
    public isDisabled(day: Date): boolean {
        day = startOfDay(day);
        if (day < this.minDate //Desativa todos os dias anteriores ao dia atual
            || day > addMinutes(new Date(), this.calendar?.eventCreationMaxThreshold.minutes || 0) //desativa todos os dias posteriores ao limite m�ximo de agendamento
            || this.daysOfWeekUnavailable?.includes(day.getDay()) //Desativa todos os dias que a autoescola n�o abre
            || this.isDayBlocked(day) //Desativa todos os dias que possuem bloqueios
        ) {
            return true;
        }
        return false;
    }

    // Verifica se um determinado dia � um dia "em cima da hora", ou seja, � menor do que o prazo m�nimo configurado no calend�rio
    public isLastMinuteDay(day: Date): boolean {
        day = startOfDay(day);
        if (day < addMinutes(startOfDay(new Date()), this.calendar?.eventCreationMinThreshold.minutes ?? 0))
            return true;
        return false;
    }

    // Verifica se existe bloqueio para um determinado dia
    private isDayBlocked(day: Date): boolean {
        return !!this.getDayBlocked(startOfDay(day));
    }

    private weeksFromDateAndMinutes(date: Date, limitInMinutes: number): number {
        const targetDate: Date = addMinutes(date, limitInMinutes);
        const diffInMilliseconds: number = targetDate.getTime() - date.getTime();
        // const diffInDays: number = (((((diffInMilliseconds / 1000) / 60) / 60) / 24));
        // const diffInWeeksDecimal: number = (((((diffInMilliseconds / 1000) / 60) / 60) / 24) / 7);
        const diffInWeeks: number = Math.floor(((((diffInMilliseconds / 1000) / 60) / 60) / 24) / 7);

        return diffInWeeks;
    }

    private weeksFromDates(dateFrom: Date, dateTo: Date): number {
        return this.weeksFromDateAndMinutes(dateFrom, ((dateTo.getTime() - dateFrom.getTime()) / 1000) / 60);
    }

    // Verifica se existe bloqueio para um determinado dia
    private getDayBlocked(day: Date): AvailabilityBlockDTO | null | undefined {
        return this.availabilityBlocks?.find((block: AvailabilityBlockDTO) => {
            const startOfCurrentDay = startOfDay(day);
            const endOfCurrentDay = endOfDay(day);
            return startOfCurrentDay.getTime() >= new Date(block.start).getTime() && endOfCurrentDay.getTime() <= new Date(block.end).getTime();
        });
    }
 
    // Verifica se existem dias da semana que n�o possuem disponibilidade cadastrada
    public get daysOfWeekUnavailable () {
        return this.resource?.availability?.availableDays?.filter(day => ((day.availableTimes?.length) || 0) == 0).map(day => day.weekday) || [];
    }

    private async fetchResource(): Promise<void> {
        this.resource = await ResourcesService.getByIdentifier(this.user.pilotar.companies[0].id); 
    }

    private async fetchCalendar(): Promise<void> {
        const calendars = await CalendarsService.getByOwner(this.user.pilotar.companies[0].id); 
        this.calendar = calendars == null ? null : calendars[0];
    }

    private async fetchAvailabilityBlocks(): Promise<void> {
        const availabilityBlocks = await AvailabilityBlocksService.getByIdentifier(this.user.pilotar.companies[0].id);
        this.availabilityBlocks = availabilityBlocks;
    }

    private async fetchData(): Promise<void> {
        this.loading = true;
        await Promise.all([
            this.fetchCalendar(),
            this.fetchResource(),
            this.fetchAvailabilityBlocks()
        ]);
        this.loading = false;
        this.$emit("loaded");
    }

    private async mounted(): Promise<void> {

        await this.fetchData();

        // Identifica a quantidade de semanas que ser�o renderizadas, baseado na configura��o de limite m�ximo para agendamento
        this.compactWeeks = this.weeksFromDates(startOfWeek(new Date()), endOfDay(addMinutes(new Date(), this.calendar?.eventCreationMaxThreshold.minutes ?? 0))) + 1;

        //Rola at� o slider que cont�m a semana da data informada na propriedade "day"
        if (this.day && this.startMode === "compact") {
            const date: Date = this.day;
            setTimeout(() => { this.slideToDate(date); }, 50);
        }

        this.dayClick(new Date());
    }

    private slideToDate(date: Date): void {
        //Quantidade de semanas da data atual at� a data informada
        const weeksFromDate = this.weeksFromDates(startOfWeek(new Date()), endOfDay(date));

        //Realiza a rolagem de sliders at� a data selecionada pelo usu�rio
        this.slideTo(weeksFromDate);
    }

    private slideTo(slide: number): void {
        const compactDateSwiperRef: any = (this.$refs.compactDateSwiper as any);
        if (compactDateSwiperRef)
            compactDateSwiperRef.swiperInstance?.slideTo(slide, 500, false);
    }

    public get dateString(): string {
        if (this.selectedDay != null) {
            return `${this.selectedDay?.getDate()} de ${MONTHS[this.selectedDay?.getMonth()]} de ${this.selectedDay?.getFullYear()}`;
        }
        return "";
    }

    public next() {
        this.$store.commit('navigate', { page: 'scheduling-calendar-teacher', transition: 'toUp' });
    }

}

export default NewSchedulingDate;
