
import SlotDTO from '@/app/DTOs/scheduling/SlotDTO';
import AppTeachersService from '@/app/services/AppTeachersService';
import { VsIcon } from '@/components/controls/vs-icon';
import AuthResponse from '@/models/AuthResponse';
import ServiceDTO from '@/modules/academic/DTOs/services/ServiceDTO';
import TeacherDTO from '@/modules/academic/DTOs/teachers/TeacherDTO';
import NotFoundMessage from '@/modules/core/components/NotFoundMessage.vue';
import store from '@/modules/scheduling/stores/newSchedulingStore';
import { formatDate, titleCase } from '@/utils/stringUtils';
import { startOfDay } from 'date-fns';
import { Component, Vue, Watch } from 'vue-property-decorator';
import TimeRangeDTO from '../DTOs/TimeRangeDTO';
import SchedulingCalendar from './SchedulingCalendar.vue';
import SchedulingSelectedItems from './SchedulingSelectedItems.vue';

@Component({ components: { VsIcon, SchedulingCalendar, SchedulingSelectedItems, NotFoundMessage } })
class NewSchedulingSlotPage extends Vue {

    public calendarLoading: boolean = true;
    public slotsLoading: boolean = true;
    public selectedDayDisabled: boolean = false;
    public transition: string = 'toUp';
    public slots: SlotDTO[] = [];
    public reasonUnavailableModal: boolean = false;
    public reasonUnavailableText: string = '';

    public get user (): AuthResponse {
        return this.$store.getters['getUser'];
    }

    public get loading (): boolean {
        return this.calendarLoading || this.slotsLoading;
    }

    public get currentPage (): number {
        return store.getters['getCurrentPage'];
    }
    
    public get selectedService (): ServiceDTO {
        return store.getters['getSelectedService'];
    }
    
    public get selectedDay (): Date {
        return store.getters['getSelectedDay'];
    }
    
    public get selectedTeacher (): TeacherDTO {
        return store.getters['getSelectedTeacher'];
    }
    
    public get selectedSlots (): SlotDTO[] {
        return store.getters['getSelectedSlots'];
    }    

    public get creditsAvailable (): number {
        if (this.selectedService)
            return this.selectedService.quantityAvailable - this.selectedSlots.length;
        return 0;
    }

    public get notFound (): boolean {
        return this.slots.length === 0 && !this.loading;
    }

    @Watch('selectedDay')
    private async onSelectedDayChange() {
        this.slots = [];
        if (!this.selectedDayDisabled) {
            this.slotsLoading = true;
            await this.fetchData();
            this.slotsLoading = false;
        }
    }

    @Watch('slots', { deep: true })
    private onSlotsChange() {
        this.$emit("change", "slot", { 
            selectedDay: this.selectedDay, 
            disabled: this.selectedDayDisabled,
            selectedSlots: this.slots.filter(slot => slot.isSelected)
        });
    }

    public startTime(slot: SlotDTO): string {
        if (slot.events.length > 0) {
            return formatDate(slot.events[0].start, "HH:mm");
        }
        return slot.startTime;
    }

    public endTime(slot: SlotDTO): string {
        if (slot.events.length > 0) {
            return formatDate(slot.events[0].end, "HH:mm");
        }
        return slot.endTime;
    }

    public slotClass(slot: SlotDTO): string {
        if (slot.hasEvents) {
            return ' scheduled';
        }
        if (slot.isSelected) {
            return ' checked';
        }
        return '';
    }

    public daySelect(day: Date, isDisabled: boolean): void {
        this.selectedDayDisabled = isDisabled;
        store.commit("setSelectedDay", day);
    }

    public openRulesInfo(): void {
        this.$store.commit('navigate', { page: "new-scheduling-rules-info", transition: "toUp" });
    }

    public calendarLoaded(): void {
        this.calendarLoading = false;
    }

    public click(index: number) {
        const slot: SlotDTO = this.slots[index];
        if (this.selectedService) {
            if (slot.isAvailable && !slot.hasEvents) {
                const selected = !slot.isSelected;
       
                // Permite marcar itens apenas se existem cr�ditos dispon�veis
                if ((this.creditsAvailable > 0 && selected) || !selected) {
                    this.slots[index].isSelected = selected;
                    setTimeout(async () => {
                        this.$emit("showBlockingLoading");
                        await this.fetchData();
                        this.$emit("hideBlockingLoading");
                    }, 0);
                }
                else {
                    this.$emit("needMoreCredits", "Adicione cr�ditos para agendar mais aulas", `No momento voc� possui ${this.selectedService.quantityAvailable} cr�dito${this.selectedService.quantityAvailable == 1 ? '' : 's'} dispon�ve${this.selectedService.quantityAvailable == 1 ? 'l' : 'is'}, mas voc� pode agendar mais aulas adicionando novos cr�ditos agora.`);
                }
            }
            else if (slot.hasEvents) {
                this.$pilotarAlert(
                    'Hor�rio agendado',
                    `Aula j� agendada para esse hor�rio na <b>${slot.events[0].serviceTypeProcessCategory.longDescription}</b>.<br/><br/><b>Instrutor(a):</b><br/>${titleCase(slot.events[0].participants.find(participant => participant.labels.includes('instructor'))?.user.name)}`, 
                    [{ title: 'Ok' }],
                    'failed'
                );
            }
            else {
                this.$pilotarAlert('Hor�rio indispon�vel', slot.reasonOfUnavailable, [{ title: 'Ok' }], 'failed');
            }
        }
    }
    
    private async fetchData() {
        await this.fetchAvailableSlotsData();
    }

    private async fetchAvailableSlotsData() {
        if (this.selectedDay && this.selectedService) {
            const slots: SlotDTO[] = await AppTeachersService.getAvailableSlotsForScheduling(this.selectedTeacher.id, startOfDay(this.selectedDay).toJSON(), this.selectedSlots as TimeRangeDTO[] );
            if (slots != null) {
                this.slots = slots.sort((a, b) => a.startTimeMinutes > b.startTimeMinutes ? 1 : -1);
            }
        }
    }


    private async mounted() {
        this.slotsLoading = true;
        try {
            await this.fetchData();
        }
        catch(error) {
            this.$pilotarAlert('Opss...', 'Erro ao conectar no servidor, tente novamente em alguns instantes', [
                {
                    title: "Tentar novamente",
                    primary: true
                }
            ]);
        }
        finally {
            this.slotsLoading = false;
        }

        setTimeout(() => {
            this.transition = 'none';
        }, 100);
    }
}

export default NewSchedulingSlotPage;
