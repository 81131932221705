import _Vue, { PluginFunction, VueConstructor } from "vue";
import { VsAlertInstance } from '@/components/layouts/vs-alert';

import PAlert from '@/components/layouts/p-Alert.vue';

const PilotarAlert: PluginFunction<null> = (Vue: VueConstructor) => {
    // ============ Plugin Alert ==============
    const alertRoot = new _Vue({
        render: createElement => createElement(PAlert),
    });

    alertRoot.$mount(document.body.appendChild(document.createElement('div')));
    const alert = alertRoot.$children[0] as VsAlertInstance;

    Vue.prototype.$pilotarAlert = alert.showAlert;
};

export default PilotarAlert;