
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VsIcon } from '@/components/controls/vs-icon';
import { isToday } from 'date-fns';

@Component({ components: { VsIcon } })
class SchedulingDay extends Vue {

    @Prop({ type: Date, default: new Date() })
    public day!: Date;

    @Prop({ type: Boolean, default: false })
    public disabled!: boolean;

    @Prop({ type: String, default: "52px" })
    public dayHeight!: string;

    @Prop({ type: String, default: "" })
    public containerStyle!: string;

    @Prop({ type: Boolean, required: true, default: false })
    public selected!: boolean;

    @Prop({ type: Boolean, default: false })
    public displayMonthName!: boolean;

    private MONTHS = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    public click() {
        this.$emit("click");
    }

    public isToday(day: Date): boolean {
        return isToday(day);
    }

    public get monthName(): string {

        if (this.day.getDate() === 1)
            return this.MONTHS[this.day.getMonth()];
        return "";
    }

    public dayCaption(day: Date): string {
        return `${day.getDate() < 10 ? 0 : ''}${day.getDate()}`;
    }
}

export default SchedulingDay;
