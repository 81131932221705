import SlotDTO from '@/app/DTOs/scheduling/SlotDTO';
import ServiceDTO from '@/modules/academic/DTOs/services/ServiceDTO';
import TeacherDTO from '@/modules/academic/DTOs/teachers/TeacherDTO';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import CalendarDTO from '../DTOs/calendars/CalendarDTO';
import AddressDTO from '@/modules/core/DTOs/AddressDTO';

export interface SchedulingState {
    calendar: CalendarDTO | null;
    currentPage: number;
    selectedService: ServiceDTO | null;
    selectedDay: Date | null;
    selectedTeacher: TeacherDTO | null;
    selectedAddress: AddressDTO | null;
    selectedSlots: SlotDTO[];
}

const newSchedulingStore: StoreOptions<SchedulingState> = {
    state: {
        calendar: null,
        currentPage: 0,
        selectedService: null,
        selectedDay: new Date(),
        selectedTeacher: null,
        selectedAddress: null,
        selectedSlots: [],
    },
    getters: {
        getCalendar(state: SchedulingState): CalendarDTO | null {
            return state.calendar;
        },
        getCurrentPage(state: SchedulingState): number {
            return state.currentPage;
        },
        getSelectedService(state: SchedulingState): ServiceDTO | null {
            return state.selectedService;
        },
        getSelectedDay(state: SchedulingState): Date | null {
            return state.selectedDay;
        },
        getSelectedTeacher(state: SchedulingState): TeacherDTO | null {
            return state.selectedTeacher;
        },
        getSelectedAddress(state: SchedulingState): AddressDTO | null {
            return state.selectedAddress;
        },
        getSelectedSlots(state: SchedulingState): SlotDTO[] {
            return state.selectedSlots;
        },
        getConsecutiveSlots(state: SchedulingState): SlotDTO[] {
            return state.selectedSlots.filter((slot: SlotDTO) => slot.consecutiveType === "begin");
        }
    },
    mutations: {
        setCalendar(state: SchedulingState, calendar: CalendarDTO): void {
            state.calendar = calendar;
        },
        setCurrentPage(state: SchedulingState, currentPage: number): void {
            state.currentPage = currentPage;
        },
        setSelectedService(state: SchedulingState, selectedService): void {
            state.selectedService = selectedService;
        },
        setSelectedDay(state: SchedulingState, selectedDay: Date): void {
            state.selectedDay = selectedDay;
            state.selectedSlots  = [];
        },
        setSelectedTeacher(state: SchedulingState, selectedTeacher: TeacherDTO): void {
            state.selectedTeacher = selectedTeacher;
        },
        setSelectedAddress(state: SchedulingState, selectedAddress: AddressDTO): void {
            state.selectedAddress = selectedAddress;
        },
        setSelectedSlots(state: SchedulingState, selectedSlots: SlotDTO[]): void {
            state.selectedSlots = selectedSlots;
        },
        reset(state: SchedulingState): void {
            state.currentPage = 0;
            state.selectedSlots  = [];
        },
    },
    actions: {
    }
};
Vue.use(Vuex);
const vuex = new Vuex.Store(newSchedulingStore);
export default vuex;