import axios from 'axios';
import qs from 'qs';
import AutorizacaoService from '@/services/AutorizacaoService';
import $store from '@/store/index';
import { parseJwt } from '@/utils/stringUtils';

const baseUrl = process.env.VUE_APP_API_URL;

const http =  axios.create({
    timeout: 30000,
    baseURL: baseUrl,
    paramsSerializer: params => qs.stringify(params)
});


http.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const tokenExpiration = new Date(parseJwt(error.config.headers["Authorization"].replace("bearer ", "")).exp * 1000);
        if (tokenExpiration <= new Date()) {
            $store.commit('logout');
            $store.commit('clearStack', { page: 'autoescolas', transition: 'crossFade' });
        }

        /*if (error.response.status === 401) {
            console.log("deslogar 2.2!!");
        }*/
        return error;
    }
);

http.interceptors.request.use(
    async config => {
        config.headers = {
            'Accept': 'application/json',
            'Content': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': ' GET, PUT, POST, DELETE, OPTIONS, post, get',
            'Access-Control-Max-Age': '3600',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization': `Bearer ${AutorizacaoService.getToken() || ''}`,
            'AccessToken': `${AutorizacaoService.getTokenBasic() || ''}`
        };
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

export default http;