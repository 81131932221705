
import { Component, Vue } from 'vue-property-decorator';
import { VsIcon } from '@/components/controls/vs-icon';
import Lottie from '@/components/Lottie.vue';
import store from '@/modules/scheduling/stores/newSchedulingStore';
import SlotDTO from '@/app/DTOs/scheduling/SlotDTO';

@Component({ components: { VsIcon, Lottie } })
class NewSchedulingDonePage extends Vue {


    public get selectedSlots (): SlotDTO[] {
        return store.getters['getSelectedSlots'];
    }

    public get errorSlots (): SlotDTO[] {
        return this.selectedSlots.filter(slot => slot.error);
    }

    public get okSlots (): SlotDTO[] {
        return this.selectedSlots.filter(slot => slot.error == null || slot.error === '');
    }

    private async mounted() {
        console.log(this.errorSlots);
    }

}

export default NewSchedulingDonePage;
