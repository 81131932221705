
import { Component, Vue } from 'vue-property-decorator';
import { VsIcon } from '@/components/controls/vs-icon';
import CalendarDTO from "@/modules/scheduling/DTOs/calendars/CalendarDTO";
import CalendarsService from "@/modules/scheduling/services/CalendarsService";
import AppSchedulingService from "@/app/services/AppSchedulingService";
import EventDetailsDTO from "@/app/DTOs/scheduling/EventDetailsDTO";

@Component({ components: { VsIcon } })
class SchedulingViewCancelConfirmation extends Vue {

    public loading: boolean = true;
    public saving: boolean = false;

    public calendar: CalendarDTO | null = null;
    public evt: EventDetailsDTO | null = null;

    public async confirmSchedulingCancel() {
        this.saving = true;

        try {
            await AppSchedulingService.cancelEvent(this.evt?.id || '', this.willLoseCredits);

            this.$pilotarAlert('Aula cancelada com sucesso', 'Sua aula pr�tica foi cancelada e a autoescola foi notificada.', [{
                title: 'Ok',
                action: () => {
                    this.$store.commit('navigate', { page: "aulas", transition: "toUpReverse" });
                    this.saving = false;
                    this.$destroy();
                }
            }], 'none', { imageType: "lottie", lottieName: "done" });
        }
        catch(error) {
            this.$pilotarAlert('A aula n�o foi cancelada', 'Ocorreu um erro ao cancelar a aula, tente novamente mais tarde.');
            this.saving = false;
        }
    }

    public get willLoseCredits (): boolean {
        if (this.evt && this.calendar)
            return ((new Date(this.evt.start).getTime() - new Date().getTime()) / 60000) < this.calendar?.eventCancelationThreshold.minutes;

        return false;
    }

    public close() {
        this.$store.commit('goBack');
        this.$destroy();
    }
    
    private async fetchData() {
        
        this.loading = true;

        this.evt = (this.$route.params.event as any) as EventDetailsDTO;

        try {
            this.calendar = await CalendarsService.getById(this.evt.calendarId);
        }
        catch(error) {
            this.$pilotarAlert('Opss...', 'Erro ao conectar no servidor, tente novamente em alguns instantes', [
                {
                    title: "Tentar novamente",
                    primary: true
                }
            ]);
        }
        finally {
            this.loading = false;
        }
    }    

    private mounted() {
        this.fetchData();
    }  

}

export default SchedulingViewCancelConfirmation;
