
import { VsIcon } from '@/components/controls/vs-icon';
import ServiceDTO from '@/modules/academic/DTOs/services/ServiceDTO';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CalendarDTO from '../DTOs/calendars/CalendarDTO';
import SchedulingCalendar from './SchedulingCalendar.vue';
import SchedulingSelectedItems from './SchedulingSelectedItems.vue';

@Component({ components: { VsIcon, SchedulingCalendar, SchedulingSelectedItems } })
class NewSchedulingDatePage extends Vue {

    @Prop({ type: Date })
    public initialDay!: Date;

    @Prop({ type: Object as () => ServiceDTO })
    public initialService!: ServiceDTO;

    public loading: boolean = true;
    public selectedDay: any | null = this.initialDay;
    public selectedService: ServiceDTO = this.initialService;

    public daySelect(day: any, isDisabled: boolean, calendar: CalendarDTO): void {
        this.selectedDay = day;
        this.$emit("change", "date", { selectedDay: this.selectedDay, disabled: isDisabled, calendar: calendar });
    }

}

export default NewSchedulingDatePage;
