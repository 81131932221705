
import { VsIcon } from '@/components/controls/vs-icon';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { VsIcon } })
class NewSchedulingRulesInfoPage extends Vue {


}

export default NewSchedulingRulesInfoPage;
