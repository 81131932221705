
import { VsIcon } from '@/components/controls/vs-icon';
import ServiceDTO from '@/modules/academic/DTOs/services/ServiceDTO';
import TeacherDTO from '@/modules/academic/DTOs/teachers/TeacherDTO';
import ImageViewer from '@/modules/storage/components/ImageViewer.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: { VsIcon, ImageViewer } })
class SchedulingSelectedItems extends Vue {

    @Prop({ type: Object as () => TeacherDTO })
    public selectedTeacher!: TeacherDTO;

    @Prop({ type: Object as () => ServiceDTO })
    public selectedService!: ServiceDTO
    ;
}

export default SchedulingSelectedItems;
