
import { VsIcon } from '@/components/controls/vs-icon';
import AuthResponse from '@/models/AuthResponse';
import ServiceDTO from '@/modules/academic/DTOs/services/ServiceDTO';
import StudentDTO from '@/modules/academic/DTOs/students/StudentDTO';
import StudentsService from '@/modules/academic/services/StudentsService';
import CompaniesService from '@/modules/core/services/CompaniesService';
import UserAvatar from '@/modules/auth/components/userAvatar/UserAvatar.vue';
import AddressDTO from '@/modules/core/DTOs/AddressDTO';
import NotFoundMessage from '@/modules/core/components/NotFoundMessage.vue';
import { titleCase, addressText } from '@/utils/stringUtils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SchedulingCalendar from './SchedulingCalendar.vue';
import SchedulingSelectedItems from './SchedulingSelectedItems.vue';
import CompanyDTO from '@/modules/core/DTOs/companies/CompanyDTO';

@Component({ components: { VsIcon, SchedulingCalendar, SchedulingSelectedItems, NotFoundMessage, UserAvatar } })
export default class NewSchedulingPickupLocationPage extends Vue {

    @Prop({ type: Object as () => ServiceDTO })
    public initialService!: ServiceDTO;

    @Prop({ type: Object as () => AddressDTO })
    public initialAddress!: AddressDTO;

    public studentLoading: boolean = true;
    public selectedDayDisabled: boolean = false;
    public selectedAddress: AddressDTO | null = this.initialAddress;
    public selectedService: ServiceDTO = this.initialService;
    public addresses: AddressDTO[] | null = null;

    public get user (): AuthResponse {
        return this.$store.getters['getUser'];
    }

    public get loading (): boolean {
        return this.studentLoading;
    }

    public get notFound (): boolean {
        return this.addresses != null && this.addresses.length === 0;
    }

    public titleCase (text: string): string {
        return titleCase(text);
    }

    public addressText (address: AddressDTO): string {
        return addressText(address);
    }

    public click(address: AddressDTO) {
        this.selectedAddress = address;
    }    

    public next() {
        this.$store.commit('navigate', { 
            page: 'new-scheduling-confirmation',
            params: { 
                selectedAddress: this.selectedAddress,
                selectedService: this.$route.params.selectedService
            }, 
            transition: 'toUp'
        });
    }
    
    private async fetchData() {
        this.studentLoading = true;

        const addresses: AddressDTO[] = [];

        try {
            const student: StudentDTO = await StudentsService.getByUserId(this.user.pilotar.user.id);
            if (student != null) {
                const company: CompanyDTO = await CompaniesService.getByIdentityDocument(student.company.identityDocument.name, student.company.identityDocument.value);

                if (company != null && company.address != null && company.address.zipCode != null && company.address.zipCode != "") {
                    company.address.name = company.name;
                    addresses.push(company.address);
                }

                if (student.address.zipCode != null && student.address.zipCode != "" && student.address.tags?.includes("pickupLocation")) {
                    addresses.push(student.address);
                }
                
                for(const address of student.additionalAddresses) {
                    if (address.zipCode != null && address.zipCode != "" && address.tags?.includes("pickupLocation")) {
                        addresses.push(address);
                    }
                }
                this.addresses = addresses;

                if (this.addresses.length == 1) {
                    this.selectedAddress = this.addresses[0];
                }
            }
        }
        catch(error) {
            this.$pilotarAlert('Opss...', 'Erro ao conectar no servidor, tente novamente em alguns instantes', [
                {
                    title: "Tentar novamente",
                    primary: true
                }
            ]);
        }
        finally {
            this.studentLoading = false;
        }
    }

    private async mounted() {
        await this.fetchData();
    }

    @Watch('selectedAddress')
    onSelectedAddressChange(address: AddressDTO) {
        this.$emit("change", "pickupLocation", { 
            selectedAddress: address
        });
    }     
}

