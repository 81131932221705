
import { Component, Vue } from 'vue-property-decorator';
import { VsIcon } from '@/components/controls/vs-icon';
import SchedulingSelectedItems from './SchedulingSelectedItems.vue';
import EventDetailsDTO from '@/app/DTOs/scheduling/EventDetailsDTO';
import AppSchedulingService from '@/app/services/AppSchedulingService';
import SchedulingDetails from './SchedulingDetails.vue';

@Component({ components: { VsIcon, SchedulingSelectedItems, SchedulingDetails } })
class SchedulingViewPage extends Vue {

    public loading: boolean = true;
    public details: EventDetailsDTO | null = null;

    private async fetchData() {
        this.loading = true;

        try {
            const details: EventDetailsDTO = await AppSchedulingService.getSchedulingEventDetails(this.$route.params.id);
            if (details != null) {
                this.details = details;
            }
        }
        catch(error) {
            this.$pilotarAlert('Opss...', 'Erro ao conectar no servidor, tente novamente em alguns instantes', [
                {
                    title: "Tentar novamente",
                    primary: true
                }
            ]);
        }
        finally {
            this.loading = false;
        }
    }

    public inPast() {
        if (this.details) {
            return new Date(this.details.start).getTime() < new Date().getTime();
        }
        return false;
    }

    public close() {
        this.$store.commit('clearStack', { page: "aulas", transition: "toUpReverse" });
        this.$destroy();
    }

    public cancelScheduling() {
        this.$store.commit('navigate', { page: "scheduling-cancel-confirmation", transition: "toUp", params: { event: this.details } });
    }

    private mounted() {
        this.fetchData();
    }  
    
}

export default SchedulingViewPage;
