

import FilesService from "../services/FilesService";
import FileDTO from "../DTOs/FileDTO";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({ components: { } })
class ImageViewer extends Vue {

    @Prop({ type: String })
    public folder!: string;

    @Prop({ type: String })
    public imageStyle!: string;

    @Prop({ type: Number, default: 0 })
    public maxFiles!: number;

    public files: FileDTO[] | null = null;
    public loading: boolean = true;

    private async mounted(): Promise<void> {
        this.fetchData();
    }

    @Watch('folder')
    onFolderChange() {
        this.fetchData();
    }

    private async fetchData(): Promise<void> {
        this.$emit('loadStart');
        this.loading = true;
        this.files = await FilesService.getByFolder(this.folder, true);
        this.loading = false;
        this.$emit('loadFinish', this.files);
    }
}

export default ImageViewer;

