
import { VsIcon } from '@/components/controls/vs-icon';
import ServiceDTO from '@/modules/academic/DTOs/services/ServiceDTO';
import TeacherDTO from '@/modules/academic/DTOs/teachers/TeacherDTO';
import ImageViewer from '@/modules/storage/components/ImageViewer.vue';
import { dateText } from '@/utils/dateUtils';
import { addMinutes, startOfDay } from 'date-fns';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CalendarDTO from '../DTOs/calendars/CalendarDTO';
import TimeSlotDTO from '../DTOs/calendars/TimeSlotDTO';
import SchedulingCalendar from './SchedulingCalendar.vue';
import SchedulingSelectedItems from './SchedulingSelectedItems.vue';
import AddressDTO from '@/modules/core/DTOs/AddressDTO';
import { titleCase, addressText } from '@/utils/stringUtils';

interface Slot extends TimeSlotDTO {
    selected: boolean;
}

@Component({ components: { VsIcon, SchedulingCalendar, SchedulingSelectedItems, ImageViewer } })
class NewSchedulingConfirmationPage extends Vue {

    @Prop({ type: Date })
    public initialDay!: Date;

    @Prop({ type: Object as () => ServiceDTO })
    public initialService!: ServiceDTO;

    @Prop({ type: Object as () => TeacherDTO })
    public initialTeacher!: TeacherDTO;

    @Prop({ type: Object as () => AddressDTO })
    public initialAddress!: AddressDTO;

    @Prop({ type: Array as () => Slot[] })
    public initialSlots!: Slot[];

    @Prop({ type: Object as () => CalendarDTO })
    public calendar!: CalendarDTO;

    public navigate(page: string) {
        this.$emit("edit", page);
    }    

    public titleCase (text: string): string {
        return titleCase(text);
    }

    public addressText (address: AddressDTO): string {
        return addressText(address);
    }

    public get dateString(): string {
        if (this.initialDay != null)
            return dateText(this.initialDay);
        return "";
    }

    public get creditsText(): string {
        const slots: number = this.initialSlots.length;
        return `${slots} cr�dito${slots > 1 ? 's' : ''}`;
    }

    // Verifica se um determinado dia � um dia "em cima da hora", ou seja, � menor do que o prazo m�nimo configurado no calend�rio
    public isLastMinuteDay(): boolean {
        const day = startOfDay(this.initialDay);
        if (day < addMinutes(startOfDay(new Date()), this.calendar?.eventCreationMinThreshold.minutes ?? 0))
            return true;
        return false;
    }    
}

export default NewSchedulingConfirmationPage;
