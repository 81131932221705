
import AppSchedulingService from '@/app/services/AppSchedulingService';
import { VsIcon } from '@/components/controls/vs-icon';
import ProcessDTO from '@/modules/academic/DTOs/processes/ProcessDTO';
import ServiceDTO from '@/modules/academic/DTOs/services/ServiceDTO';
import ServicesService from '@/modules/academic/services/ServicesService';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ components: { VsIcon } })
class NewScheduling extends Vue {

    @Prop({ type: Object as () => ProcessDTO })
    public initialService!: ServiceDTO;

    private audience: string = localStorage.getItem("session#pilotarapp#audience") ?? process.env.VUE_APP_DEFAULT_AUDIENCE;
    public fromPainel: boolean = this.audience == "painel";

    public loading: boolean = true;
    public checkingCredits: boolean = false;
    public addCreditsModalVisible: boolean = false;

    public selectedServiceId: string | null = null;
    public services: ServiceDTO[] | null = null;

    public async checkCredits() {

        if (!this.checkingCredits) {
            this.$emit("checkingCredits", true);
            this.checkingCredits = true;
    
            await AppSchedulingService.checkCredits();
            await this.fetchData();
    
            this.checkingCredits = false;
            this.$emit("checkingCredits", false);
        }
        
    }

    public get processesTypes (): any {
        if (this.services) {
            return Array.from(new Set(this.services.map(service => service.process.type.id)));
        }
        return null;
    }

    public servicesByProcessId (processTypeId: string): ServiceDTO[] | null {
        let ret: ServiceDTO[] | null = null;
        if (this.services) {
            ret = this.services.filter(service => service.process.type.id == processTypeId) ?? [];

        }
        return ret;
    }
    
    public click(service: ServiceDTO) {
        if (!this.checkingCredits) {
            if (service.quantityAvailable > 0) {
                this.selectedServiceId = service.id;
                this.$emit("change", "category", service );
            }
            else {
                this.$emit(
                    "needMoreCredits",
                    "Adicione cr�ditos para continuar agendando", 
                    this.fromPainel 
                        ? "O aluno n�o possui cr�ditos dispon�veis nessa categoria. Adicione cr�ditos para agendar mais aulas." 
                        : "Voc� n�o possui cr�ditos dispon�veis nessa categoria. Entre em contato com sua autoescola para adquirir mais cr�ditos de aulas pr�ticas."
                );
            }
        }
    }

    public async fetchData() {
        const services: ServiceDTO[] = await ServicesService.getToSchedulingPracticalLessons();
        this.services = services;
        if (this.initialService?.id) {
            const service = this.services?.find(s => s.id === this.initialService.id);
            if ((service?.quantityAvailable || 0) > 0) {
                this.selectedServiceId = this.initialService.id;
            }
            else {
                this.selectedServiceId = null;
                this.$emit("change", "category", null);
            }
        }
    }

    private async mounted() {
        this.loading = true;
        await this.fetchData();
        this.loading = false;
    }
}

export default NewScheduling;
