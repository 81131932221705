import SurveyDTO from '@/modules/rating/DTOs/surveys/SurveyDTO';
import { Get } from '../../../services/BaseService';

export default class SurveysService{

    public static readonly baseUrl = process.env.VUE_APP_API_FIREBASE_URL;

    public static getById(id: string): Promise<SurveyDTO>{
        return Get<SurveyDTO>(`${this.baseUrl}/rating/surveys/get-by-id?id=${id}`);
    }
}