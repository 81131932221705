import { CreateRating, Rating } from '@/models/Rating';
import RatingResponseDTO from '@/modules/rating/DTOs/ratings/RatingResponseDTO';
import SurveyStatsDTO from '@/modules/rating/DTOs/surveys/SurveyStatsDTO';
import httpPilotar from '@/plugins/httpPilotar';
import { Get, Post } from '../../../services/BaseService';

export default class RatingService{

    public static readonly baseUrl = process.env.VUE_APP_API_FIREBASE_URL;

    public static getSurveyStatsByCNPJ(cnpj: string): Promise<SurveyStatsDTO>{
        return Get<SurveyStatsDTO>(`${this.baseUrl}/rating/surveys/stats/get-by-field-field-value?surveyId=${process.env.VUE_APP_SURVEY_ID}&field=metadata.cfc.cnpj&fieldValue=${cnpj}`);
    }

    public static getRatingsByCNPJ(cnpj: string, page: string): Promise<RatingResponseDTO>{
        return Get<RatingResponseDTO>(`${this.baseUrl}/rating/ratings/get-by-survey-id?surveyId=${process.env.VUE_APP_SURVEY_ID}&where=metadata.cfc.cnpj eq '${cnpj}'&orderBy=createdAt desc&limit=30&page=${page || ''}`);
    }

    public static createRating(rating: CreateRating) {
        return Post<CreateRating, Rating>(`${this.baseUrl}/rating/ratings/create`, rating, httpPilotar);
    }
}