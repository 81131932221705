import httpPilotar from '@/plugins/httpPilotar';
import { Get } from '@/services/BaseService';
import CalendarDTO from '../DTOs/calendars/CalendarDTO';

export default class CalendarsService {

    public static getByOwner(identifier: string): Promise<CalendarDTO[]>{
        return Get<CalendarDTO[]>(`/scheduling/calendars/get-by-owner`, { "identifier": identifier }, { customClient: httpPilotar });
    }
  
    public static getById(id: string): Promise<CalendarDTO>{
        return Get<CalendarDTO>(`/scheduling/calendars/get-by-id`, { "id": id }, { customClient: httpPilotar });
    }
  
}